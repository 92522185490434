<template>
  <div class="">
    <div class="form-row">
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-success mb-3 mr-2"
          @click="addForm">
          เพิ่ม
        </button>
        <button
          type="button"
          class="btn btn-primary mb-3"
          @click="toList">
          กลับ
        </button>
      </div>

      <div class="col"></div>

      <sgv-input-text
        class="col-sm-auto col-12"
        v-model="search"
        placeholder="ค้นหา...">
      </sgv-input-text>
    </div>

    <DocConfigUserManualCard
      :templateType="templateType"
      :docType="docType"
      :isForm.sync="isForm"
      @created="refetch">
    </DocConfigUserManualCard>

    <DocConfigUserManualCard
      v-for="item in items"
      :key="item.id"
      :templateType="templateType"
      :docType="docType"
      :item="item"
      @updated="refetch"
      @destroyed="refetch">
    </DocConfigUserManualCard>
  </div>
</template>

<script>
import DocConfigUserManualCard from './DocConfigUserManualCard.vue'
import { LIST_DOC_CONFIG_USER_MANUAL } from './graph/docConfig.userManual'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      search: '',
      items: [],
      isForm: false
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_DOC_CONFIG_USER_MANUAL(this.templateType)
      },
      fetchPolicy: 'cache-and-network',
      variables () {
        return {
          docType: this.docType,
          q: {
            params: {
              search: this.search,
            }
          }
        }
      },
      debounce: 250
    }
  },
  methods: {
    addForm () {
      if (this.isForm) return
      this.isForm = true
    },
    toList () {
      this.$router.push({
        name: `Doc${this.$form.capitalize(this.docType)}List`
      })
    },
    refetch () {
      this.$apollo.queries.items.refetch()
    }
  },
  components: {
    DocConfigUserManualCard
  }
}
</script>

<style lang="css">

</style>
