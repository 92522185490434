<template>
  <div class="">
    <div class="card" v-if="!toggle && item">
      <div class="card-body">
        <span class="card-text">
          <span
            class="mx-1 pointer float-right"
            @click="toggle = !toggle">
            <fa icon="pencil-alt" class="text-warning"></fa>
          </span>
          <div class="pre-line">
            {{formData.paper.code}} ({{formData.paper.name}})
          </div>
        </span>
      </div>
    </div>

    <div class="card" v-if="toggle">
      <div class="card-body" v-if="toggle">
        <div class="card-text">
          <div class="form-row">
            <DocConfigUserManualCardPaper
              :templateType="templateType"
              :docType="docType"
              label="คู่มือการทำงาน"
              class="col-12"
              select="id"
              v-model="formData.paperId"
              :validations="[
                {text: 'required!', value: $v.formData.paperId.$dirty && !$v.formData.paperId.required}
              ]">
            </DocConfigUserManualCardPaper>
          </div>

          <button
            v-if="isForm"
            type="button"
            class="btn btn-success"
            @click="createData">
            เพิ่ม
          </button>

          <button
            v-if="!isForm"
            type="button"
            class="btn btn-warning"
            @click="updateData">
            แก้ไข
          </button>

          <button
            v-if="toggle"
            type="button"
            class="btn btn-primary ml-2"
            @click="cancelData">
            ยกเลิก
          </button>

          <button
            v-if="!isForm"
            type="button"
            class="btn btn-danger ml-2 float-right"
            @click="destroyData">
            ลบ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigUserManualCardPaper from './DocConfigUserManualCardPaper'
import {
  CREATE_DOC_CONFIG_USER_MANUAL,
  UPDATE_DOC_CONFIG_USER_MANUAL,
  DESTROY_DOC_CONFIG_USER_MANUAL,
} from './graph/docConfig.userManual'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
    isForm: {
      type: Boolean,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        paperId: null,
        paper: null
      }
    }
  },
  validations: {
    formData: {
      paperId: {required},
    }
  },
  methods: {
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return {
        paperId: v.paperId
      }
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_USER_MANUAL(this.templateType),
        variables: {
          docType: this.docType,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$emit('created', res.data.item)
        this.toggle = false
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_DOC_CONFIG_USER_MANUAL(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.item.id,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$emit('updated', res.data.item)
        this.toggle = false
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_USER_MANUAL(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.item.id
        }
      })
      .then(res => {
        this.$emit('destroyed', res.data.item)
        this.toggle = false
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.paperId = this.item.paperId
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      } else {
        this.$v.formData.$reset()
        this.formData.paperId = null
        this.$emit('update:isForm', false)
      }
    },
    isForm (value) {
      if (value) {
        this.toggle = true
      }
    }
  },
  created () {
    if (this.item) {
      this.setFormData(this.item)
    }
  },
  components: {
    DocConfigUserManualCardPaper
  }
}
</script>

<style lang="css" scoped>
</style>
