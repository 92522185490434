import gql from 'graphql-tag'


const docConfigResponse = `
  id type docType
  paperId paper {id type code name}
`

export const LIST_DOC_CONFIG_USER_MANUAL = (templateType) => gql`query LIST_DOC_CONFIG ($docType: String!, $q: FilterInput) {
  items: list${templateType}DocConfigUserManual (docType: $docType, q: $q) {${docConfigResponse}}
}`

export const LIST_DOC_CONFIG_USER_MANUAL_DROPDOWN_PAPER = (templateType) => gql`query LIST_DOC_CONFIG_USER_MANUAL_DROPDOWN_PAPER ($docType: String!, $q: FilterInput) {
  items: list${templateType}DocConfigUserManualDropdownPaper (docType: $docType, q: $q) {
    id type code name
  }
}`

export const CREATE_DOC_CONFIG_USER_MANUAL = (templateType) => gql`mutation CREATE_DOC_CONFIG_USER_MANUAL ($docType: String!, $input: ${templateType}DocConfigUserManualInput!) {
  item: create${templateType}DocConfigUserManual (docType: $docType, input: $input) {${docConfigResponse}}
}`

export const UPDATE_DOC_CONFIG_USER_MANUAL = (templateType) => gql`mutation UPDATE_DOC_CONFIG_USER_MANUAL ($docType: String!, $docConfigId: Int!, $input: ${templateType}DocConfigUserManualInput!) {
  item: update${templateType}DocConfigUserManual (docType: $docType, docConfigId: $docConfigId, input: $input) {${docConfigResponse}}
}`

export const DESTROY_DOC_CONFIG_USER_MANUAL = (templateType) => gql`mutation DESTROY_DOC_CONFIG_USER_MANUAL ($docType: String!, $docConfigId: Int!) {
  item: destroy${templateType}DocConfigUserManual (docType: $docType, docConfigId: $docConfigId) {id}
}`
